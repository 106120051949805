// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require chosen-jquery
//= require scaffold


require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("bootstrap")
require("chartkick")
require("chart.js")


import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chartkick.use(Chart)

import $ from 'jquery'
import 'select2/dist/css/select2.css'
import 'select2'

/// if you don't have turnbolinks then use this:
//// document.addEventListener('DOMContentLoaded', () => { 
window.addEventListener('turbolinks:load', () => {
  $('.select2').select2({
    tags: true,
    theme: bootstrap,
    tokenSeparators: [',', ' ']
  });
})





